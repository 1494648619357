import React, {useEffect, useState} from 'react';
import {Container, Navbar} from "react-bootstrap";
import logo from "../../assets/img/logo-no-background.png";
import styles from "./ProjectDetails.module.css";
import projImg1 from "../../assets/img/project-img1.png";

const ProjectDetails = () => {
    const [scrolled, setScrolled] = useState(false);
    const [mainImage, setMainImage] = useState(projImg1);


    // const [images, setImages] = useState([
    //     "https://i.pinimg.com/originals/50/20/d3/5020d3860125ae769f0068870bc22d05.jpg",
    //     "https://i.ytimg.com/vi/tFcqkhL4hic/maxresdefault.jpg",
    //     "https://www.supercars.net/blog/wp-content/uploads/2017/11/BMW-E60-M5.png",
    //     "https://www.maxim.com/wp-content/uploads/2022/07/1980_bmw_m1_002_web-scaled-1.webp"
    // ]);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const handleThumbnailClick = (imageUrl) => {
        setMainImage(imageUrl);
    };

    return (
        <div>
            <Navbar expand="sm" className={scrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Logo"/>
                    </Navbar.Brand>
                </Container>
            </Navbar>

            <div className={`container ${styles.projectDetailsContainer}`}>
                <h1 className="my-4">
                    <a style={{textDecoration: "none", color: "#fff"}}
                                        href="https://www.drivesalez.site">
                    DriveSalez
                </a>
                </h1>

                <div className="row">
                    <div className="col-md-8">
                        <img className="img-fluid" src={mainImage} alt=""/>
                    </div>
                    <div className="col-md-4">
                        <h3 className="my-3">Project Description</h3>
                        <p>Drivesafe is an innovative online car sales platform that applies advanced technology ASP.NET
                            . In the frontend of our project, we use modern tools such as React and Redux Toolkit. These
                            technologies allow us to create dynamic and interactive user interfaces, providing
                            convenience and smooth operation for our users.</p>
                        <h3 className="my-3">Project Details</h3>
                        <ul className={styles.detailsList}>
                            <li>Personal accounts and free announcements: Users can create their own account and place
                                one free announcement
                                per month. If the user exhausts his free options, he is offered the opportunity to
                                purchase additional ads if necessary.
                            </li>
                            <li>Premium Announcements: Our platform offers premium announcements that stand out at the
                                top of the list and promote faster car sales.
                            </li>
                            <li>Administrators have the ability to add ready-made data that users use when creating
                                announcements,
                                such as body types, transmission types, brands, models, etc.
                            </li>
                            <li>Moderators check user announcements for compliance with the rules of the platform. They
                                approve
                                them before publishing. Announcements are not displayed to other users until approved.
                                Artificial
                                intelligence is also used to remove inappropriate ads.
                            </li>
                            <li>Content management: Administrators can also add ready-made data that users use when
                                creating ads, such as body types, transmission types, brands, models, etc.
                            </li>
                        </ul>
                        <a href="https://www.canva.com/design/DAGA6M3DkfA/PcEVeadsjT5t6OsrIvrNxg/edit?utm_content=DAGA6M3DkfA&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                           className="mt-4" target="_blank" rel="noopener noreferrer">
                            View Presentaion on Canva
                        </a>
                    </div>
                </div>

                {/*<h3 className="my-4">Related Projects</h3>*/}
                {/*<div className="row">*/}
                {/*    {images.map((item) => (*/}
                {/*        <div key={item} className="col-md-3 col-sm-6 mb-4">*/}
                {/*            <a href="#" onClick={(e) => {*/}
                {/*                e.preventDefault();*/}
                {/*                handleThumbnailClick(item);*/}
                {/*            }}>*/}
                {/*                <img className="img-fluid" src={item} alt=""/>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default ProjectDetails;
