import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Footer } from "../components/Footer";
import { Navbar, Container } from "react-bootstrap";
import logo from "../assets/img/logo-no-background.png";
const TeamMember = ({teamMember}) => {

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    return (
        <div>

            <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Logo"  />
                    </Navbar.Brand>
                </Container>
            </Navbar>

            <div id="hero" className="hero route bg-image" style={{backgroundImage: `url(${teamMember.backgroundPictureUrl})`}} >
                <div className="overlay-itro"></div>
                <div className="hero-content display-table">
                    <div className="table-cell">
                        <div className="container">
                            <h1 className="hero-title mb-4">I am {teamMember.name}</h1>
                            <p className="hero-subtitle"><span className="typed"
                                                               data-typed-items="Designer, Developer, Freelancer, Photographer"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <main id="main" className={"mg-lg-5"}>

                <section id="about" className="about-mf sect-pt4 route">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="box-shadow-full">
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="row justify-content-center">
                                                    <div className="col-sm-12 col-md-6 col-lg-5">
                                                        <div className="about-img mb-4">

                                                            <img src={teamMember.profilePictureUrl}
                                                                 className="img-fluid rounded b-shadow-a" alt="Profile Picture"/>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 col-lg-7">
                                                        <div className="about-info mb-4">
                                                            <div className="info-item">
                                                                <p className="title-s">Name:</p>
                                                                <p className="text-black-50 ml-2">{teamMember.name}</p>
                                                            </div>
                                                            <div className="info-item">
                                                                <p className="title-s">Role:</p>
                                                                <p className="text-black-50 ml-2">{teamMember.role}</p>
                                                            </div>
                                                            <div className="info-item">
                                                                <p className="title-s">Email:</p>
                                                                <p className="text-black-50 ml-2">{teamMember.email}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="skill-mf">
                                                <p className="title-s">Skill</p>

                                                {teamMember.skills.map((skill) => (
                                                    <React.Fragment key={skill.name}>
                                                        <span>{skill.name}</span> <span
                                                        className="pull-right">{skill.percentage}%</span>
                                                        <div className="progress">
                                                            <div className="progress-bar" role="progressbar"
                                                                 style={{width: `${skill.percentage}%`}}
                                                                 aria-valuenow={skill.percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}


                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="about-me pt-4 pt-md-0">
                                                <div className="title-box-2">
                                                    <h5 className="title-left text-dark">
                                                        About me
                                                    </h5>
                                                </div>
                                                <p className="lead text-black-50">
                                                    {teamMember.biography}
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>


            <Footer isMailChipActive={false}/>
        </div>
    );
};

export default TeamMember;