import React from 'react';
import styles from "./NotFound.module.css"

const NotFound = () => {
    return (

        <div className={styles.container}>

            <div className={styles.main}>

                <div className={styles.error}>
                    <div className={styles.wrap}>
                        <div className='404'>
                            <pre><code>{"\n"}{"\t"} <span className={styles.green}>&lt;!</span><span>DOCTYPE html</span><span
                                className={styles.green}>&gt;</span>{"\n"}<span
                                className={styles.orange}>&lt;html&gt;</span>{"\n"}{"    "}<span
                                className={styles.orange}>&lt;style&gt;</span>{"\n"}{"   "}* {"{"}{"\n"}{"\t"}{"\t"}{"        "}<span
                                className={styles.green}>everything</span>:<span
                                className={styles.blue}>awesome</span>;{"\n"}{"}"}{"\n"}{"     "}<span
                                className={styles.orange}>&lt;/style&gt;</span>{"\n"} <span
                                className={styles.orange}>&lt;body&gt;</span> {"\n"}{"              "}ERROR 404!{"\n"}{"\t"}{"\t"}{"\t"}{"\t"}FILE NOT FOUND!{"\n"}{"\t"}{"\t"}{"\t"}{"\t"}<span
                                className={styles.comment}>&lt;!--The file you are looking for, {"\n"}{"\t"}{"\t"}{"\t"}{"\t"}{"\t"}is not where you think it is.--&gt;{"\n"}{"\t"}{"\t"}</span>{"\n"}
                                <span className={styles.orange}/> {"\n"}{"\t"}{"\t"}{"\t"}{"  "}{"\n"}{"\n"}{"\n"}</code></pre>
                        </div>
                        <code>
                            <br/>
                            <span className={styles.info}>
                <br/>
                <span className={styles.orange}>&nbsp;&lt;/body&gt;</span>
                <br/>
                <span className={styles.orange}>&lt;/html&gt;</span>
              </span></code>
                    </div>
                </div>
            </div>


</div>


)
    ;
};

export default NotFound;