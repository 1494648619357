import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TeamMember from "./pages/TeamMember"
import NotFound from "./pages/NotFound/NotFound";
import teamData from "./data/teamMembersData.json"
import ProjectDetails from "./pages/ProjectDetails/ProjectDetails";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/ismayilzada" element={<TeamMember teamMember={teamData.teamMembers[0]}/>} />
        <Route path="/ahmadzada" element={<TeamMember teamMember={teamData.teamMembers[1]}/>} />
        <Route path="/projects/drivesalez" element={<ProjectDetails/>} />
        <Route path="*" element={<NotFound/>} />
    </Routes>
</BrowserRouter>
);

reportWebVitals();
