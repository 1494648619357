import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo-no-background.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = ({isMailChipActive}) => {
  let date = new Date().getFullYear()
  
  return (
    <footer className="footer pt-2">
      <Container>
        <Row className="align-items-center">
          {isMailChipActive && <MailchimpForm />}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              {/*<a href="#"><img src={navIcon1} alt="Icon" /></a>*/}
              {/*<a href="#"><img src={navIcon2} alt="Icon" /></a>*/}
              {/*<a href="#"><img src={navIcon3} alt="Icon" /></a>*/}
            </div>
            <p>Copyright © {date}. All Rights Reserved.</p>
            <p>Site by Ismayilzada Mahammad.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
